import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./styles.module.scss";
import { Loader } from "../Loader";

type Props = {
  children?: ReactNode;
  className?: string;
  loading?: boolean;
};

export const Page = ({ children, className, loading }: Props) => {
  return (
    <div className={classNames(styles.page, className)}>
      {loading ? <Loader /> : children}
    </div>
  );
};

import {
  Price,
  Product,
  ProductCategory,
  ProductCollection,
} from "../api-client/types";

export const sortedCollections: ProductCollection[] = [
  "Harry Potter",
  "Tolkien",
  "Saisons",
];

export const sortedCategories: ProductCategory[] = [
  "Thés",
  "Infusions",
  "Bougies",
  "Tote bag",
  "Cartes",
  "Marque-pages",
];

export const isPreorder = (product: Product) => {
  if (!product.metadata.release_date) return false;

  return new Date(product.metadata.release_date) > new Date();
};

export const isNewProduct = (product: Product) => {
  if (!product.metadata.release_date) return false;

  const releaseDate = new Date(product.metadata.release_date);
  const today = new Date();

  const monthDiff = today.getMonth() - releaseDate.getMonth();
  const yearDiff = today.getFullYear() - releaseDate.getFullYear();

  const diff = monthDiff - yearDiff * 12;

  return releaseDate < today && diff >= 0 && diff < 2;
};

export const isSoldOut = (prices: Price[]) => {
  return !prices.find((price) => price.metadata.quantity_left !== "0");
};

export const formatDate = (date?: string | number) => {
  if (!date) return null;

  return new Date(date).toLocaleString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

import styles from "./styles.module.scss";
import { Page, Title } from "../common-ui";
import { eventList } from "./utils";

export const Events = () => {
  const years: string[] = Object.keys(eventList).reverse();

  return (
    <Page className={styles.container}>
      <Title>Évènements</Title>

      <div className={styles.description}>
        En plus de la vente en ligne, je me rends aussi régulièrement sur des
        marchés pour y exposer mes produits.
      </div>

      <div className={styles.description}>
        Voici la liste des évènements durant lesquels j'ai exposé :
      </div>

      {years.map((year) => (
        <div>
          <div className={styles.year}>{`Évènements ${year}`}</div>

          <div>
            {eventList[year].map((event) => (
              <div className={styles.event}>
                <div className={styles.name}>
                  {new Date() < new Date(event.date.start) ? "⏳" : "✅"}{" "}
                  {event.name}
                </div>
                <div>
                  {event.date.detail} à {event.location}
                </div>

                <div>{event.description}</div>

                {event.images.length ? (
                  <div className={styles.images}>
                    {event.images.map((image) => (
                      <img src={image} alt={event.name} />
                    ))}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ))}
    </Page>
  );
};

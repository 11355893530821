import styles from "./styles.module.scss";

import { CHRISTMAS_PROMO_CODE } from "../../../containers/config";

type Props = {
  isClosed: boolean;
  setIsClosed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ChristmasPromoBanner = ({ isClosed, setIsClosed }: Props) => {
  return (
    <div className={styles.banner}>
      Profitez de -15% sur toute la boutique avec le code "
      <b>{CHRISTMAS_PROMO_CODE}</b>" 🎄🎁
    </div>
  );
};

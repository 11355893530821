import ReactGA from "react-ga4";

import { Button, Page, Section } from "../common-ui";

import homepage_1 from "../../assets/images/products/tolkien/the_semi-homme_boite.jpg";
import homepage_2 from "../../assets/images/homepage_2.jpg";
import homepage_3 from "../../assets/images/homepage_3.png";

import styles from "./styles.module.scss";
import { HomepageBanner } from "./HomepageBanner";

export const Homepage = () => {
  return (
    <>
      <HomepageBanner />

      <Page>
        <div>
          <Section
            title="Mes recettes originales"
            image={homepage_1}
            content={
              <>
                <div>
                  Je vous invite à découvrir mes recettes originales de thés bio
                  ainsi que mes illustrations peintes à l’aquarelle sur des
                  thèmes qui m’inspirent.
                </div>
                <div>
                  <strong>
                    Les recettes des mélanges de thés sont pensées et imaginées
                    afin de créer des expériences les plus immersives possible
                    en fonction de leur univers.
                  </strong>{" "}
                  La réalisation de ces recettes est ensuite faite sur mesure,
                  les thés sont <strong>biologiques</strong> et les
                  illustrations des packaging sont{" "}
                  <strong>peintes à l'aquarelle</strong> par mes soins.
                </div>
                <div>
                  De nombreux articles de papeterie tels que : des marque-pages,
                  des blocs notes, des cartes et des affiches, sont en cours de
                  réalisation et arriveront très prochainement dans la boutique.
                  Celle-ci sera d'ailleurs en perpetuelle évolution avec, au
                  programme, de nouvelles collections à thème, et de nouveaux
                  articles dans la collection Tolkien.
                </div>
                <Button
                  to="/shop/"
                  className={styles.shopCta}
                  onClick={() =>
                    ReactGA.event({
                      category: "CTA",
                      action: "go_to_shop_homepage",
                    })
                  }
                >
                  Aller voir la boutique
                </Button>
              </>
            }
          />

          <Section
            title="Des mélanges de thé biologiques"
            image={homepage_3}
            content={
              <>
                <div>
                  Tous les mélanges de thé sont{" "}
                  <strong>certifiés biologiques</strong>. Ils sont réalisés sur
                  mesure pour une dégustation immersive. Les illustrations des
                  étiquettes des thés sont peintes à la main à l’aquarelle et
                  représentent l'ambiance spécifique de chaque thé.
                </div>
                <Button
                  to="/shop/"
                  className={styles.shopCta}
                  onClick={() =>
                    ReactGA.event({
                      category: "CTA",
                      action: "go_to_shop_homepage",
                    })
                  }
                >
                  Aller voir la boutique
                </Button>
              </>
            }
          />

          <Section
            title="L'aquarelle"
            image={homepage_2}
            content={
              <>
                <div>
                  La base de tout ce projet est essentiellement de créer des
                  illustrations à l'aquarelle.{" "}
                  <strong>
                    Toute une collection de cartes, de marque-pages, d'affiches
                    et de bloc-notes est en cours d'élaboration.
                  </strong>
                </div>
                <div>
                  J’aimerais aussi proposer des produits tels que des bougies,
                  des mugs, des tote bags, et autres. Plusieurs collections sont
                  prévues, inspirées par de nombreux thèmes, larges et variés{" "}
                  <em>
                    {" "}
                    (Game of Thrones, Harry Potter, Disney, Agatha Christie, les
                    saisons et fêtes de saison, les Oiseaux, les Chiens,
                    etc...).
                  </em>
                </div>
                <div>
                  Un agrandissement de la collection de Tolkien est aussi en
                  cours.{" "}
                  <strong>
                    {" "}
                    Les différentes collections seront en perpetuelle évolution
                    avec des nouveautés régulières.
                  </strong>
                </div>
                <Button
                  to="/shop/"
                  className={styles.shopCta}
                  onClick={() =>
                    ReactGA.event({
                      category: "CTA",
                      action: "go_to_shop_homepage",
                    })
                  }
                >
                  Aller voir la boutique
                </Button>
              </>
            }
          />
        </div>
      </Page>
    </>
  );
};

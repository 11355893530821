import yffrendil_1 from "../../assets/images/events/yffrendil_2024_1.png";
import yffrendil_2 from "../../assets/images/events/yffrendil_2024_2.png";

import medieval_throne_1 from "../../assets/images/events/medieval__throne_1.png";

import cidre_2024_1 from "../../assets/images/events/cidre_2024_1.png";
import cidre_2024_2 from "../../assets/images/events/cidre_2024_2.png";

import fenrir_2024_1 from "../../assets/images/events/fenrir_2024_1.png";
import fenrir_2024_2 from "../../assets/images/events/fenrir_2024_3.png";

export type Event = {
  name: string;
  date: { detail: string; start: string };
  description: string;
  location: string;
  images: string[];
};

export const eventList: { [year: string]: Event[] } = {
  2025: [
    {
      name: "Fenrir",
      date: { detail: "24 & 25 Mai 2025", start: "2025-05-24" },
      description: "Festival Médiéval-Fantastique",
      location: "Liffré (35)",
      images: [],
    },
  ],
  2024: [
    {
      name: "Marché de Noël de la Bergerie Nationale de Rambouillet",
      date: { detail: "15 Décembre 2024", start: "2024-12-15" },
      description: "Marché de Noël artisanal",
      location: "la Bergerie Nationale de Rambouillet",
      images: [],
    },
    {
      name: "Yffrendil Festival de Noël",
      date: { detail: "23 & 24 Novembre 2024", start: "2024-11-23" },
      description: "Marché de Noël Médiéval-Fantastique",
      location: "Yvignac-la-Tour (22)",
      images: [yffrendil_1, yffrendil_2],
    },
    {
      name: "Médieval Throne",
      date: { detail: "5 & 6 Octobre 2024", start: "2024-10-05" },
      description: "Festival Médiéval-Fantastique",
      location: "Yvré-l'Évêque (72)",
      images: [medieval_throne_1],
    },
    {
      name: "Cidre et Dragon",
      date: { detail: "21 & 22 Septembre 2024", start: "2024-09-21" },
      description: "Festival Médiéval-Fantastique",
      location: "Merville-Franceville (14)",
      images: [cidre_2024_1, cidre_2024_2],
    },
    {
      name: "Fenrir",
      date: { detail: "17 & 18 Mai 2024", start: "2024-05-17" },
      description: "Festival Médiéval-Fantastique",
      location: "Liffré (35)",
      images: [fenrir_2024_1, fenrir_2024_2],
    },
  ],
  2023: [
    {
      name: "Marché de Noël de la Bergerie Nationale de Rambouillet (78)",
      date: { detail: "10 Décembre 2023", start: "2023-12-10" },
      description: "Marché de Noël artisanal",
      location: "la Bergerie Nationale de Rambouillet",
      images: [],
    },
  ],
};

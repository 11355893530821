import { NavLink } from "react-router-dom";
import { instagram, mail, pinterest } from "../../legal/utils";

import iconMail from "../../../assets/icons/mail.svg";
import iconInstagram from "../../../assets/icons/instagram.svg";
import iconPinterest from "../../../assets/icons/pinterest.svg";
import logo from "../../../assets/logos/Logo.png";

import styles from "./styles.module.scss";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div>
          <div>Politiques</div>
          <NavLink to="legal">Mentions légales</NavLink>
          <NavLink to="cgv">Conditions Générales de Ventes</NavLink>
          <NavLink to="cgu">Conditions Générales d'Utilisation</NavLink>
          <NavLink to="refund">Remboursement</NavLink>
        </div>
        <div>
          <div>À Propos</div>
          <NavLink to="events">Évènements</NavLink>
          <NavLink to="contact">Contact</NavLink>
          <NavLink to="faq">FAQ</NavLink>
          <NavLink to="stripe">Stripe</NavLink>
        </div>
        <div>
          <div>Contact</div>
          <a
            href={`mailto:${mail}`}
            className={styles.contact}
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconMail} alt="mail" />
            <div className={styles.contactName}>{mail}</div>
          </a>
          <a
            href={`https://www.instagram.com/${instagram}/`}
            className={styles.contact}
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconInstagram} alt="instagram" />
            <div className={styles.contactName}>@{instagram}</div>
          </a>
          <a
            href={`https://www.pinterest.fr/${pinterest}/`}
            className={styles.contact}
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconPinterest} alt="pinterest" />
            <div className={styles.contactName}>Cosy Fantasy</div>
          </a>
        </div>
        <div>
          <div className={styles.logo}>
            <img src={logo} alt="Cosy Fantasy logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

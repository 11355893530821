import classNames from "classnames";
import { useEffect, useState } from "react";
import { apiClient } from "../../api-client/apiClient";
import { Price, Product } from "../../api-client/types";
import { Page, Title } from "../../common-ui";
import { SelectInput } from "../../common-ui/SelectInput";
import { SelectInputOption } from "../../common-ui/SelectInput/SelectInput";
import { EmptyMsg } from "../EmptyMsg";

import { ProductCard } from "../ProductCard";
import { sortedCategories, sortedCollections } from "../utils";

import styles from "./styles.module.scss";

export const Shop = () => {
  const allCategoryOption = { value: "all", label: "Toutes les catégories" };
  const allCollectionOption = { value: "all", label: "Toutes les collections" };

  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [collections, setCollections] = useState<string[]>([]);
  const [filters, setFilters] = useState({
    category: allCategoryOption.value,
    collection: allCollectionOption.value,
  });
  const [prices, setPrices] = useState<Price[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiClient.products.list().then((products_response) => {
      setProducts(products_response);

      const categoryList: string[] = [];
      const collectionList: string[] = [];
      products_response.forEach((p) => {
        if (p.metadata.category && !categoryList.includes(p.metadata.category))
          categoryList.push(p.metadata.category);
        if (
          p.metadata.collection &&
          !collectionList.includes(p.metadata.collection)
        )
          collectionList.push(p.metadata.collection);
      });
      setCategories(categoryList);
      setCollections(collectionList);

      apiClient.prices.list().then((prices_response) => {
        setPrices(prices_response);
        setLoading(false);
      });
    });
  }, []);

  // COLLECTION OPTIONS
  const collectionOptionsTmp = sortedCollections
    .filter((collection) => collections.find((c) => c === collection))
    .map((c) => ({
      value: c,
      label: c,
    }));
  const collectionOptions: SelectInputOption[] = [
    allCollectionOption,
    ...collectionOptionsTmp,
  ];

  // CATEGORY OPTIONS

  const categoryOptionsTmp = sortedCategories
    .filter((category) => categories.find((c) => c === category))
    .map((c) => ({
      value: c,
      label: c,
    }));
  const categoryOptions: SelectInputOption[] = [
    allCategoryOption,
    ...categoryOptionsTmp,
  ];

  // FILTERED PRODUCTS
  const filteredProducts = products.filter(
    (product) =>
      (filters.collection !== "all"
        ? product.metadata.collection === filters.collection
        : true) &&
      (filters.category !== "all"
        ? product.metadata.category === filters.category
        : true)
  );

  // FILTERED CATEGORIES
  const filteredCategories = sortedCategories.filter((category) =>
    filteredProducts.find((product) => product.metadata.category === category)
  );

  // FILTERED COLLECTION
  const filteredCollections = sortedCollections.filter((collection) =>
    filteredProducts.find(
      (product) => product.metadata.collection === collection
    )
  );

  const displayCategoryTitle = (
    category: string | undefined,
    collection: string | undefined
  ) => {
    return products.find(
      (p) =>
        p.metadata.category === category && p.metadata.collection === collection
    );
  };

  const sortedProducts = (products: Product[]) => {
    return products.sort((a, b) => {
      const productAPrices = prices.filter((price) => price.product === a.id);
      const productBPrices = prices.filter((price) => price.product === b.id);

      const productAQuantityLeft = productAPrices.reduce(
        (total, price) => total + Number(price.metadata.quantity_left ?? 100),
        0
      );
      const productBQuantityLeft = productBPrices.reduce(
        (total, price) => total + Number(price.metadata.quantity_left ?? 100),
        0
      );
      return productBQuantityLeft - productAQuantityLeft;
    });
  };

  return (
    <Page loading={loading}>
      <Title>Boutique</Title>

      <div className={styles.selectInputs}>
        <div className={styles.filters}>Filtres : </div>
        <SelectInput
          options={collectionOptions}
          onChange={(e) => setFilters({ ...filters, collection: e.value })}
          value={filters.collection}
        />
        <SelectInput
          options={categoryOptions}
          onChange={(e) => setFilters({ ...filters, category: e.value })}
          value={filters.category}
        />
      </div>

      {!filteredProducts.length ? <EmptyMsg /> : null}
      {filteredCollections.map((collection) => (
        <>
          <div
            className={classNames(styles.collectionTitle, {
              [styles.lotr]: collection === "Tolkien",
              [styles.hp]: collection === "Harry Potter",
            })}
          >
            {collection}
          </div>
          {filteredCategories.map((category) => (
            <>
              {displayCategoryTitle(category, collection) ? (
                <Title subtitle>{category}</Title>
              ) : null}
              <div className={styles.products}>
                {sortedProducts(
                  products.filter(
                    (p) =>
                      p.metadata.collection === collection &&
                      p.metadata.category === category
                  )
                ).map((product) => (
                  <ProductCard
                    product={product}
                    prices={prices.filter(
                      (price) => price.product === product.id
                    )}
                  />
                ))}
              </div>
            </>
          ))}
        </>
      ))}
    </Page>
  );
};

import { User } from "firebase/auth";
import ReactGA from "react-ga4";
import avatar from "../../../assets/icons/avatar.svg";

import styles from "./styles.module.scss";
import { ReactNode } from "react";

export const sendGAEvent = (category: string, action: string) => {
  ReactGA.event({
    category,
    action,
  });
};

export const isAuthenticated = (user: User | null) => !!user;
export const isNotAuthenticated = (user: User | null) => user === null;

type Tab = { label: string | ReactNode; to: string; id: string };
type Tabs = { left: Tab[]; right: Tab[] };

export const navigation = (user: User | null) => {
  const tabs: Tabs = {
    left: [
      { label: "Accueil", to: "/", id: "home" },
      { label: "Boutique", to: "/shop/", id: "shop" },
      { label: "Évènements", to: "/events/", id: "events" },
      { label: "À propos", to: "/about/", id: "about" },
      { label: "FAQ", to: "/faq/", id: "faq" },
      { label: "Contact", to: "/contact/", id: "contact" },
    ],
    right: [],
  };

  if (isNotAuthenticated(user)) {
    tabs.right.push(
      { label: "Connexion", to: "/login/", id: "login" },
      { label: "Créer mon compte", to: "/register/", id: "register" }
    );
  }

  if (isAuthenticated(user)) {
    tabs.right.push({
      label: (
        <>
          <img
            src={user?.photoURL || avatar}
            className={styles.avatar}
            alt="avatar"
          />
          {user?.displayName}
        </>
      ),
      to: "/account/informations/",
      id: "account",
    });
  }

  return tabs;
};

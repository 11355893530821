import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { Homepage } from "../modules/home";
import { ProductDetail, Shop } from "../modules/shop";
import { Cart, PaymentSuccess } from "../modules/cart";
import { Faq, Stripe } from "../modules/faq";
import { Contact } from "../modules/contact";
import {
  LegalNotice,
  Refund,
  TermsOfSales,
  TermsOfUse,
} from "../modules/legal";
import {
  Login,
  Register,
  Orders,
  Account,
  Informations,
} from "../modules/account";
import { About } from "../modules/about";
import { Events } from "../modules/events";

export const Router = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    window.scroll({ top: 0 });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />

      <Route path="/login/" element={<Login />} />
      <Route path="/register/" element={<Register />} />
      <Route path="/account/" element={<Account />}>
        <Route path="/account/informations/" element={<Informations />} />
        <Route path="/account/orders/" element={<Orders />} />
      </Route>

      <Route path="/shop/:id/" element={<ProductDetail />} />
      <Route path="/shop/" element={<Shop />} />
      <Route path="/cart/" element={<Cart />} />
      <Route path="/payment/success/" element={<PaymentSuccess />} />

      <Route path="/faq/" element={<Faq />} />
      <Route path="/stripe/" element={<Stripe />} />
      <Route path="/contact/" element={<Contact />} />
      <Route path="/events/" element={<Events />} />
      <Route path="/legal/" element={<LegalNotice />} />
      <Route path="/cgv/" element={<TermsOfSales />} />
      <Route path="/cgu/" element={<TermsOfUse />} />
      <Route path="/refund/" element={<Refund />} />
      <Route path="/about/" element={<About />} />
    </Routes>
  );
};

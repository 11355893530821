import { Button } from "../../common-ui";
import styles from "./styles.module.scss";

import logo from "../../../assets/logos/logo-white.png";

export const HomepageBanner = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.overlay} />

      <div className={styles.content}>
        <h1 className={styles.title}>Bienvenue chez</h1>

        <img src={logo} className={styles.logo} alt="logo" />

        <div className={styles.description}>
          Vous retrouverez ici toutes mes recettes originales de thés
          fantastiques.
        </div>

        <Button to="/shop/">Accéder à la boutique</Button>
      </div>
    </div>
  );
};

import { ReactNode, useContext, useState } from "react";

import { Footer } from "../Footer";
import { Header } from "../Header";
import { Toasts } from "../Toasts";

import styles from "./styles.module.scss";
import { StoreContext } from "../../../containers/StoreContext";
import { WelcomePromoToast } from "../../home/WelcomePromoToast";
import { DISPLAY_CHRISTMAS_OFFER } from "../../../containers/config";
import { ChristmasPromoBanner } from "../../home/ChristmasPromoBanner";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const store = useContext(StoreContext);
  const [homeToastIsClosed, setHomeToastIsClosed] = useState(false);
  return (
    <>
      {/* user === null => request finished and no user */}
      {store.user === null && !DISPLAY_CHRISTMAS_OFFER ? (
        <WelcomePromoToast
          isClosed={homeToastIsClosed}
          setIsClosed={setHomeToastIsClosed}
        />
      ) : null}

      <Toasts />
      <Header />

      {DISPLAY_CHRISTMAS_OFFER && (
        <ChristmasPromoBanner
          isClosed={homeToastIsClosed}
          setIsClosed={setHomeToastIsClosed}
        />
      )}

      <div className={styles.layout}>{children}</div>
      <Footer />
    </>
  );
};
